import Client from '@/repositories/Clients/AxiosClient';
import Config from '@/repositories/Clients/AxiosDefaultConfig';
import { prepareSearchParams } from '@/libs/helper';

const resource = `${Config.basePath}/v1/api/admin/livepre`;

export default {
  index(payload) {
    payload.searchFields = [
      ...payload.searchFields,
    ];
    payload = {
      ...payload,
    };
    const params = prepareSearchParams(payload);
    return Client.post(resource, {
      ...params
    }, {
      headers: {
        action: "list",
      },
    });
  },
  create(payload) {
    const params = {
      ...payload,
    };
    return Client.post(resource, params, {
      headers: {
        action: "store",
      },
    });
  },
  show(id, payload = {}) {
    const params = {
      id: id,
    };
    return Client.post(resource, params, {
      headers: {
        action: "show",
      },
    });
  },
  update(id, payload) {
    const params = {
      ...payload,
      id: id,
    };
    return Client.post(resource, params, {
      headers: {
        action: "update",
      },
    });
  },
  destroy(id) {
    return Client.post(resource, {
      id: id,
    }, {
      headers: {
        action: "delete",
      },
    });
  },
  generateResult(payload) {
    return Client.post(resource, payload, {
      headers: {
        action: "generate",
      },
    });
  },
  generateMatchResult(payload) {
    return Client.post(resource, payload, {
      headers: {
        action: "generate-match-result",
      },
    });
  },
  generateMatchAndResult(payload) {
    return Client.post(resource, payload, {
      headers: {
        action: "generate-match-with-result",
      },
    });
  },
  cloneByDay(payload) {
    return Client.post(resource, payload, {
      headers: {
        action: "copy-live-result-day",
      },
    });
  },
  generateByDay(payload) {
    return Client.post(resource, payload, {
      headers: {
        action: "generate-live-result-day",
      },
    });
  },
  getResultByDay(payload) {
    return Client.post(resource, payload, {
      headers: {
        action: "get-live-result-day",
      },
    });
  },
};
