import Client from '@/repositories/Clients/AxiosClient'
import Config from '@/repositories/Clients/AxiosDefaultConfig'

const resource = `${Config.basePath}/v1/api/admin/account`

export default {
  deposit(userId, payload) {
    const params = {
      amount: payload.amount,
      remark: payload.remark,
      userId,
    }
    return Client.post(resource, params, {
      headers: {
        action: 'deposit',
      },
    })
  },
  withdrawal(userId, payload) {
    const params = {
      amount: payload.amount,
      remark: payload.remark,
      userId,
    }
    return Client.post(resource, params, {
      headers: {
        action: 'withdrawal',
      },
    })
  },
  depositOrWithdraw(payload, type = 'deposit') {
    const params = {
      amount: payload.amount,
      promoAmount: type === 'deposit' ? payload.promoAmount : 0,
      remark: payload.remark,
      userId: payload.userId,
    }
    return Client.post(resource, params, {
      headers: {
        action: type,
      },
    })
  },
}
