export default [
    {
        path: '/live-betting',
        name: 'live-betting',
        component: () => import('@/views/liveBetting/Index.vue'),
        meta: {
            pageTitle: 'breadcrumb.liveBetting',
            resource: 'live-betting',
            action: 'read',
            breadcrumb: [
                {
                    text: 'breadcrumb.liveBetting',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/live-betting/live-setup',
        name: 'live-setup',
        component: () => import('@/views/liveBetting/Index.vue'),
        meta: {
            pageTitle: 'breadcrumb.liveBetting',
            navActiveLink: 'live-betting',
            resource: 'live-betting',
            action: 'read',
            breadcrumb: [
                {
                    text: 'breadcrumb.liveSetup',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/live-betting/data-form',
        name: 'data-form',
        component: () => import('@/views/liveBetting/Index.vue'),
        meta: {
            pageTitle: 'breadcrumb.dataForm',
            navActiveLink: 'live-betting',
            resource: 'live-betting',
            action: 'read',
            breadcrumb: [
                {
                    text: 'breadcrumb.dataForm',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/live-betting/live-stream',
        name: 'live-stream',
        component: () => import('@/views/liveBetting/Index.vue'),
        meta: {
            pageTitle: 'breadcrumb.liveBetting',
            navActiveLink: 'live-betting',
            resource: 'live-betting',
            action: 'read',
            breadcrumb: [
                {
                    text: 'breadcrumb.liveSteam',
                    active: true,
                },
            ],
        },
    },
]
