import Client from '@/repositories/Clients/AxiosClient'
import Config from '@/repositories/Clients/AxiosDefaultConfig'
import { prepareSearchParams } from '@/libs/helper'

const resource = `${Config.basePath}/v1/api/admin/member`
const typeId = 2

export default {
  index(payload) {
    payload.searchFields = [...payload.searchFields, { key: 'typeId' }]
    payload = {
      ...payload,
      typeId,
    }
    const params = prepareSearchParams(payload)
    if (payload.search) {
      params.filters.push({
        searchby: 'username',
        searchoperator: '=',
        search: payload.search.toUpperCase(),
      })
    }
    return Client.post(
      resource,
      {
        ...params,
      },
      {
        headers: {
          action: 'member-list',
        },
      },
    )
  },
  listIp(payload) {
    payload.searchFields = [...payload.searchFields]
    payload = {
      ...payload,
    }
    const params = prepareSearchParams(payload)
    params.userId = payload.userId
    return Client.post(
      resource,
      {
        ...params,
      },
      {
        headers: {
          action: 'ip-member-list',
        },
      },
    )
  },
  create(payload) {
    const params = {
      ...payload,
      typeId,
    }
    return Client.post(resource, params, {
      headers: {
        action: 'member-store',
      },
    })
  },
  show(id, payload = {}) {
    const params = {
      ...payload,
      typeId,
      id,
    }
    return Client.post(resource, params, {
      headers: {
        action: 'member-show',
      },
    })
  },
  update(id, payload) {
    const params = {
      ...payload,
      typeId,
      id,
    }
    return Client.post(resource, params, {
      headers: {
        action: 'member-update',
      },
    })
  },
  getSuggestUsername(parentId = null, userId = null) {
    const params = {
      parentId,
      userId,
      typeId,
    }
    return Client.post(resource, params, {
      headers: {
        action: 'member-suggest-username',
      },
    })
  },
  checkUsername(username, id = null) {
    const params = {
      username,
      user_id: id,
    }
    return Client.post(resource, params, {
      headers: {
        action: 'member-check-username',
      },
    })
  },
  options(payload) {
    payload = {
      ...payload,
      typeId,
    }
    return Client.post(resource, payload, {
      headers: {
        action: 'list-option',
      },
    })
  },
}
