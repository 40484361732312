export default [
  {
    path: '/reward',
    name: 'list-reward',
    component: () => import('@/views/reward/List.vue'),
    meta: {
      pageTitle: 'breadcrumb.reward',
      resource: 'reward',
      action: 'read',
      breadcrumb: [
        {
          text: 'breadcrumb.listReward',
          active: true,
        },
      ],
    },
  },
  {
    path: '/reward/create',
    name: 'create-reward',
    component: () => import('@/views/reward/Create.vue'),
    meta: {
      pageTitle: 'breadcrumb.reward',
      navActiveLink: 'list-reward',
      resource: 'reward',
      action: 'create',
      breadcrumb: [
        {
          text: 'breadcrumb.listReward',
          to: { name: 'list-reward' },
        },
        {
          text: 'breadcrumb.createReward',
          active: true,
        },
      ],
    },
  },
  {
    path: '/reward/:id/update',
    name: 'update-reward',
    component: () => import('@/views/reward/Update.vue'),
    meta: {
      pageTitle: 'breadcrumb.reward',
      navActiveLink: 'list-reward',
      resource: 'reward',
      action: 'update',
      breadcrumb: [
        {
          text: 'breadcrumb.listReward',
          to: { name: 'list-reward' },
        },
        {
          text: 'breadcrumb.updateReward',
          active: true,
        },
      ],
    },
  },
  {
    path: '/reward/:id',
    name: 'view-reward',
    component: () => import('@/views/reward/View.vue'),
    meta: {
      pageTitle: 'breadcrumb.reward',
      navActiveLink: 'list-reward',
      resource: 'reward',
      action: 'read',
      breadcrumb: [
        {
          text: 'breadcrumb.listReward',
          to: { name: 'list-reward' },
        },
        {
          text: 'breadcrumb.viewReward',
          active: true,
        },
      ],
    },
  },
]
