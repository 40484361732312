<template />

<script>
import Repository from '@/repositories/RepositoryFactory'
import { initialAbility } from '@/libs/acl/config'

const ProfileRepository = Repository.get('profile')

export default {
  created() {
    this.getProfile()
  },
  methods: {
    getProfile() {
      ProfileRepository.getProfile().then(response => {
        const data = response.data?.data
        data.ability.forEach(x => { x.subject = x.subject.toLowerCase() })
        this.$store.commit('profile/UPDATE_PROFILE', data)
        localStorage.setItem('userData', JSON.stringify(data))
        let newAbility = [...initialAbility]
        if (data.ability) {
          if (data.isReadonly) {
            data.ability.forEach(x => {
              x.action = 'read'
              return x
            })
          }
          newAbility = [...data.ability, ...initialAbility]
        }
        // Updating user ability in CASL plugin instance
        this.$ability.update([])
        this.$ability.update(newAbility)
      })
    },
  },
}
</script>
