import Client from '@/repositories/Clients/AxiosClient'
import Config from '@/repositories/Clients/AxiosDefaultConfig'
import { prepareSearchParams } from '@/libs/helper'

const resource = `${Config.basePath}/v1/api/admin/reward`

export default {
  index(payload) {
    const params = prepareSearchParams(payload)
    if (payload.search) {
      params.filters.push({
        searchby: 'reward_name',
        searchoperator: '%',
        search: payload.search,
      })
    }
    if (payload.startDate) {
      params.filters.push({
        searchby: 'created_at',
        searchoperator: '>',
        search: `${payload.startDate} 00:00:00`,
      })
    }
    if (payload.endDate) {
      params.filters.push({
        searchby: 'created_at',
        searchoperator: '<',
        search: `${payload.endDate} 23:59:59`,
      })
    }
    return Client.post(resource, {
      ...params,
    }, {
      headers: {
        action: 'reward-claim-list',
      },
    })
  },
  update(payload) {
    return Client.post(resource, payload, {
      headers: {
        action: 'reward-claim-update',
      },
    })
  },
}
