export default [
  {
    path: '/point-rule',
    name: 'point-rule',
    component: () => import('@/views/pointRule/Update.vue'),
    meta: {
      pageTitle: 'breadcrumb.pointRule',
      resource: 'point-rule',
      action: 'read',
      breadcrumb: [
        {
          text: 'breadcrumb.pointRule',
          active: true,
        },
      ],
    },
  },
]
