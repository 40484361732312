import Client from '@/repositories/Clients/AxiosClient';
import Config from '@/repositories/Clients/AxiosDefaultConfig';
import { preparePaginationParams } from '@/libs/helper';

const resource = `${Config.basePath}/v1/api/admin/fight`;

export default {
  listParley(payload) {
    return Client.post(resource, payload, {
      headers: {
        action: "list-parley",
      }
    });
  },
  saveParley(payload) {
    return Client.post(resource, payload, {
      headers: {
        action: "update-parley",
      }
    });
  },
  updateParleyStatus(payload) {
    return Client.post(resource, payload, {
      headers: {
        action: "update-fight-live-parley-status",
      }
    });
  },
  listParleyDefault(payload) {
    return Client.post(resource, payload, {
      headers: {
        action: "list-parley-default",
      }
    });
  },
  saveParleyDefault(payload) {
    return Client.post(resource, payload, {
      headers: {
        action: "update-parley-default",
      }
    });
  },
};
