export default [
    {
        path: '/report/parley',
        name: 'parley-report',
        // component: () => import('@/views/parleyHierarchyReport/List.vue'),
        component: () => import('@/views/parleyReport/List.vue'),
        meta: {
            pageTitle: 'breadcrumb.parleyReport',
            resource: 'parley-report',
            action: 'read',
            breadcrumb: [
                {
                    text: 'breadcrumb.listParleyReport',
                    active: true,
                },
            ],
        },
    },
]
