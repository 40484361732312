export default [
    {
        path: '/slot-balance-report',
        name: 'slot-balance-report',
        component: () => import('@/views/slotBalanceReport/List.vue'),
        meta: {
            pageTitle: 'breadcrumb.slotBalanceReport',
            resource: 'balance-report',
            action: 'read',
            breadcrumb: [
                {
                    text: 'breadcrumb.listBalanceReport',
                    active: true,
                },
            ],
        },
    },
]
