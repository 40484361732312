import ProfileRepository from './Api/ProfileRepository'
import CurrencyRepository from './Api/CurrencyRepository'
import UserRepository from './Api/UserRepository'
import RoleRepository from './Api/RoleRepository'
import PermissionRepository from './Api/PermissionRepository'
import CompanyRepository from './Api/CompanyRepository'
import SeniorRepository from './Api/SeniorRepository'
import MasterRepository from './Api/MasterRepository'
import AgentRepository from './Api/AgentRepository'
import PlayerRepository from './Api/PlayerRepository'
import AccountRepository from './Api/AccountRepository'
import TransactionRepository from './Api/TransactionRepository'
import BalanceReportRepository from './Api/BalanceReportRepository'
import DashboardRepository from './Api/DashboardRepository'
import BetSettingRepository from './Api/BetSettingRepository'
import WithdrawRequestRepository from './Api/WithdrawRequestRepository'
import DepositRequestRepository from './Api/DepositRequestRepository'
import DepositSettingRepository from './Api/DepositSettingRepository'
import FightSetupRepository from './Api/FightSetupRepository'
import ParleySetupRepository from './Api/ParleySetupRepository'
import BetHistoryRepository from './Api/BetHistoryRepository'
import ParleyReportRepository from './Api/ParleyReportRepository'
import DomainRepository from './Api/DomainRepository'
import BaccaratBalanceReportRepository from './Api/BaccaratBalanceReportRepository'
import ChannelRepository from './Api/ChannelRepository'
import ChannelBaccarateRepository from './Api/ChannelBaccarateRepository'
import ChannelCockRepository from './Api/ChannelCockRepository'
import BaccaratResultRepository from './Api/BaccaratResultRepository'
import MemberRepository from './Api/MemberRepository'
import BetTransactionRepository from './Api/BetTransactionRepository'
import slotBalanceReportRepository from './Api/slotBalanceReportRepository'
import FightBetHistoryRepository from './Api/FightBetHistoryRepository'
import AssistantRepository from './Api/AssistantRepository'
import PromotionHistoryRepository from './Api/PromotionHistoryRepository'
import RewardRepository from './Api/RewardRepository'
import PointRuleRepository from './Api/PointRuleRepository'
import ClaimRewardHistoryRepository from './Api/ClaimRewardHistoryRepository'

const repositories = {
  dashboard: DashboardRepository,
  profile: ProfileRepository,
  currency: CurrencyRepository,
  user: UserRepository,
  role: RoleRepository,
  permission: PermissionRepository,
  company: CompanyRepository,
  senior: SeniorRepository,
  master: MasterRepository,
  agent: AgentRepository,
  player: PlayerRepository,
  account: AccountRepository,
  transaction: TransactionRepository,
  balanceReport: BalanceReportRepository,
  betSetting: BetSettingRepository,
  withdrawRequest: WithdrawRequestRepository,
  depositRequest: DepositRequestRepository,
  depositSetting: DepositSettingRepository,
  fightSetup: FightSetupRepository,
  parleySetup: ParleySetupRepository,
  betHistory: BetHistoryRepository,
  parleyReport: ParleyReportRepository,
  domain: DomainRepository,
  channel: ChannelRepository,
  channelBaccarate: ChannelBaccarateRepository,
  channelCock: ChannelCockRepository,
  baccaratBalanceReport: BaccaratBalanceReportRepository,
  baccaratResult: BaccaratResultRepository,
  member: MemberRepository,
  betTransaction: BetTransactionRepository,
  slotBalanceReport: slotBalanceReportRepository,
  fightBetHistory: FightBetHistoryRepository,
  assistant: AssistantRepository,
  promotionHistory: PromotionHistoryRepository,
  reward: RewardRepository,
  pointRule: PointRuleRepository,
  claimRewardHistory: ClaimRewardHistoryRepository,
}

export default {
  get: name => repositories[name],
}
