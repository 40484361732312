import Client from "@/repositories/Clients/AxiosClient";
import Config from "@/repositories/Clients/AxiosDefaultConfig";

const resource = `${Config.basePath}/v1/api/admin/member`;

export default {
  saveBetSetting(payload) {
    const params = {
      ...payload,
    };
    return Client.post(resource, params, {
      headers: {
        action: "update-member-channel-setting",
      },
    });
  },
  options(payload) {
    payload = {
      ...payload,
    };
    return Client.post(resource, payload, {
      headers: {
        action: "list-option",
      },
    });
  },
};
