import axios from "axios";
import useJwt from "@/auth/jwt/useJwt";
import { login, logout } from "@/auth/utils";
import router from "@/router";
import Toast from "@/libs/toast";
import { camelizeKeys, decamelize, decamelizeKeys } from "humps";
import Swal from "sweetalert2";
import { encrypt } from "@/libs/crypto-js";
import Config from "@/repositories/Clients/AxiosDefaultConfig";

const baseDomain = "/api";
const baseURL = `${baseDomain}`;
const auth = localStorage.getItem(useJwt.jwtConfig.storageTokenKeyName);

let isAlreadyFetchingAccessToken = false;
let subscribers = [];

function onAccessTokenFetched(accessToken) {
  subscribers = subscribers.filter((callback) => callback(accessToken));
}

function addSubscriber(callback) {
  subscribers.push(callback);
}
const request = axios.create({
  baseURL,
  headers: {
    Authentication: auth,
  },
});

request.interceptors.request.use(
  (config) => {
    config.headers.ADMINVERSION = Config.version;

    const locale = localStorage.getItem("locale");
    config.headers.Locale = locale == "km" ? "kh" : locale ? locale : "kh";

    // Get token from localStorage
    const accessToken = useJwt.getToken();

    config.headers.Authorization = encrypt(useJwt.jwtConfig.authorizationKey);
    // If token is present add it to request"s Authorization Header
    if (accessToken) {
      // eslint-disable-next-line no-param-reassign
      config.headers.Authentication = `${encrypt(accessToken)}`;
    }
    if (config.data) {
      config.data = decamelizeKeys(config.data);
    }
    return config;
  },
  (error) => Promise.reject(error)
);

request.interceptors.response.use(
  function (response) {
    if (response.data?.message) {
      Toast.fire({
        icon: "success",
        title: response.data.message,
      });
    }

    if (
      response.data &&
      (response.headers["content-type"] ===
        "application/json;charset=UTF-8, application/json" ||
        response.headers["content-type"] === "application/json")
    ) {
      response.data = camelizeKeys(response.data);
    }

    return Promise.resolve(response);
  },
  function (error) {
    const { config, response } = error;
    const status = response?.status;
    const originalRequest = config;
    if (status === 401) {
      const code = response?.data?.code;
      if (code == 105) {
        window.history.forward(1);
        window.location.reload(true);
      } else if (code === 102) {
        if (!isAlreadyFetchingAccessToken) {
          isAlreadyFetchingAccessToken = true;
          useJwt
            .refreshToken()
            .then((res) => {
              isAlreadyFetchingAccessToken = false;
              const data = res.data?.data;
              login(data);
              onAccessTokenFetched(data.accessToken);
            })
            .catch((e) => {
              const c = e.response?.data?.code;
              if (c === 104) {
                let m = e.response?.data?.message;
                if (status) {
                  m = `${m} (${status})`;
                }
                logout();

                // Redirect to login page
                return router.push({ name: "login" }).then(() => {
                  Swal.fire({
                    title: "Expired!",
                    icon: "info",
                    text: m,
                    customClass: {
                      confirmButton: "btn btn-primary",
                    },
                    buttonsStyling: false,
                  });
                });
              }
            });
        }

        const retryOriginalRequest = new Promise((resolve) => {
          addSubscriber((access_token) => {
            originalRequest.headers.Auth = access_token;
            resolve(request(originalRequest));
          });
        });
        return retryOriginalRequest;
      } else if (code === 101 || code === 103 || code === 104) {
        let message = error.response?.data?.message;

        if (status) {
          message = `${message} (${status})`;
        }

        logout();
        // Redirect to login page
        return router.replace({ name: "login" }).then(() => {
          Swal.fire({
            title: "Expired!",
            icon: "info",
            text: message,
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        });
      }
    }

    if (status === 404) {
      return router.push("/error-404");
    }

    if (status === 406) {
      return router.push({ name: "login" }).then(() => {
        Swal.fire({
          title: "Warning!",
          icon: "info",
          text: `Please config your device timezone as auto in order to use our platform. (${status})`,
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
      });
    }

    if (status === 422) {
      response.data.errors = camelizeKeys(response.data?.errors);
    }

    let title = "";
    let message = "";
    if (status !== 404 && status !== 401 && status !== 422) {
      title = response.data?.message;
      message = response.data?.errormesasge;
    }

    if (status) {
      message = `${message} (${status})`;
    }

    if (status === 400) {
      Swal.fire({
        title: title,
        icon: "info",
        text: message,
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      });
    } else if (status > 500) {
      Swal.fire({
        title: "Internal Server Error",
        icon: "error",
        text: `Something went wrong! Please try again! (${status})`,
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      });
    } else if (message) {
      Swal.fire({
        title: title,
        icon: "error",
        text: message,
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      });
    } else if (!message && status != 422) {
      Swal.fire({
        title: "Internal Server Error",
        icon: "error",
        text: `Something went wrong! Please try again! (${status})`,
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      });
    }

    // console.clear();
    return Promise.reject(error);
  }
);

export default request;
