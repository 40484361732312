import Client from '@/repositories/Clients/AxiosClient'
import Config from '@/repositories/Clients/AxiosDefaultConfig'
import { prepareSearchParams } from '@/libs/helper'

const resource = `${Config.basePath}/v1/api/admin/reward`

export default {
  index(payload) {
    const params = prepareSearchParams(payload)
    if (payload.search) {
      params.filters.push({
        searchby: 'reward_name',
        searchoperator: '%',
        search: payload.search,
      })
    }
    return Client.post(resource, {
      ...params,
    }, {
      headers: {
        action: 'reward-list',
      },
    })
  },
  create(payload) {
    const params = {
      ...payload,
    }
    return Client.post(resource, params, {
      headers: {
        action: 'reward-store',
      },
    })
  },
  show(id) {
    const params = {
      id,
    }
    return Client.post(resource, params, {
      headers: {
        action: 'reward-show',
      },
    })
  },
  update(id, payload) {
    const params = {
      ...payload,
      id,
    }
    return Client.post(resource, params, {
      headers: {
        action: 'reward-update',
      },
    })
  },
}
