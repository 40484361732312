import Client from "@/repositories/Clients/AxiosClient";
import Config from "@/repositories/Clients/AxiosDefaultConfig";
import { prepareSearchParams } from "@/libs/helper";

const resource = `${Config.basePath}/v1/api/admin/report/bet`;

export default {
  index(payload) {
    let params = prepareSearchParams(payload);
    return Client.post(
      `${resource}`,
      {
        ...params,
        fightMatchId: payload.fightMatchId,
        username: payload.search,
      },
      {
        headers: {
          action: "bet-transaction-report",
        },
      }
    );
  },
  total(payload) {
    let params = prepareSearchParams(payload);
    return Client.post(
      `${resource}`,
      {
        ...params,
        fightMatchId: payload.fightMatchId,
        username: payload.search,
      },
      {
        headers: {
          action: "bet-transaction-report-total",
        },
      }
    );
  },
};
