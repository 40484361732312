export default [
    {
        path: '/parley',
        name: 'parley',
        component: () => import('@/views/parley/Index.vue'),
        meta: {
            pageTitle: 'breadcrumb.parley',
            resource: 'parley-setup',
            action: 'read',
            breadcrumb: [
                {
                    text: 'breadcrumb.parley',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/parley/setup',
        name: 'parley-setup',
        component: () => import('@/views/parley/Index.vue'),
        meta: {
            pageTitle: 'breadcrumb.parley',
            navActiveLink: 'parley',
            resource: 'parley-setup',
            action: 'read',
            breadcrumb: [
                {
                    text: 'breadcrumb.parley',
                    active: false,
                },
                {
                    text: 'breadcrumb.setup',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/parley/data-form',
        name: 'parley-data-form',
        component: () => import('@/views/parley/Index.vue'),
        meta: {
            pageTitle: 'breadcrumb.parley',
            navActiveLink: 'parley',
            resource: 'parley-data-form',
            action: 'read',
            breadcrumb: [
                {
                    text: 'breadcrumb.parley',
                    active: false,
                },
                {
                    text: 'breadcrumb.dataForm',
                    active: true,
                },
            ],
        },
    },
]
