export default [
  {
    path: '/baccarat-result',
    name: 'list-baccarat-result',
    component: () => import('@/views/baccaratResult/List.vue'),
    meta: {
      pageTitle: 'breadcrumb.listBaccaratResult',
      resource: 'baccarat-result',
      action: 'read',
      breadcrumb: [
        {
          text: 'breadcrumb.listBaccaratResult',
          active: true,
        },
      ],
    },
  },
  {
    path: '/baccarat-result/create',
    name: 'create-baccarat-result',
    component: () => import('@/views/baccaratResult/Create.vue'),
    meta: {
      pageTitle: 'breadcrumb.baccaratResult',
      navActiveLink: 'list-baccarat-result',
      resource: 'baccarat-result',
      action: 'create',
      breadcrumb: [
        {
          text: 'breadcrumb.listBaccaratResult',
          to: { name: 'list-baccarat-result' }
        },
        {
          text: 'breadcrumb.createBaccaratResult',
          active: true,
        },
      ],
    },
  },
  {
    path: '/baccarat-result/generate',
    name: 'generate-baccarat-result',
    component: () => import('@/views/baccaratResult/Generate.vue'),
    meta: {
      pageTitle: 'breadcrumb.baccaratResult',
      navActiveLink: 'list-baccarat-result',
      resource: 'baccarat-result',
      action: 'create',
      breadcrumb: [
        {
          text: 'breadcrumb.listBaccaratResult',
          to: { name: 'list-baccarat-result' }
        },
        {
          text: 'breadcrumb.generateBaccaratResult',
          active: true,
        },
      ],
    },
  },
  {
    path: '/baccarat-result/:id/update',
    name: 'update-baccarat-result',
    component: () => import('@/views/baccaratResult/Update.vue'),
    meta: {
      pageTitle: 'breadcrumb.baccaratResult',
      navActiveLink: 'list-baccarat-result',
      resource: 'baccarat-result',
      action: 'update',
      breadcrumb: [
        {
          text: 'breadcrumb.listBaccaratResult',
          to: { name: 'list-baccarat-result' }
        },
        {
          text: 'breadcrumb.updateBaccaratResult',
          active: true,
        },
      ],
    },
  },
  {
    path: '/baccarat-result/:id',
    name: 'view-baccarat-result',
    component: () => import('@/views/baccaratResult/View.vue'),
    meta: {
      pageTitle: 'breadcrumb.baccaratResult',
      navActiveLink: 'list-baccarat-result',
      resource: 'baccarat-result',
      action: 'read',
      breadcrumb: [
        {
          text: 'breadcrumb.listBaccaratResult',
          to: { name: 'list-baccarat-result' }
        },
        {
          text: 'breadcrumb.viewBaccaratResult',
          active: true,
        },
      ],
    },
  },
]
