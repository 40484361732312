import Client from "@/repositories/Clients/AxiosClient";
import Config from "@/repositories/Clients/AxiosDefaultConfig";
import { prepareSearchParams } from "@/libs/helper";

const resource = `${Config.basePath}/v1/api/admin/report`;

export default {
  index(payload) {
    let params = prepareSearchParams(payload);
    params = {
      ...payload,
      ...params,
      username: payload.search,
    };
    if (payload.startDate) {
      params.filters.push({
        searchby: "created_at",
        searchoperator: ">",
        search: `${payload.startDate} 00:00:00`,
      });
    }
    if (payload.toDate) {
      params.filters.push({
        searchby: "created_at",
        searchoperator: "<",
        search: `${payload.toDate} 23:59:59`,
      });
    }
    if (payload.channelType) {
      params.filters.push({
        searchby: "channel_type",
        searchoperator: "=",
        search: payload.channelType,
      });
    }
    if (payload.channelId) {
      params.filters.push({
        searchby: "channel_id",
        searchoperator: "=",
        search: payload.channelId,
      });
    }
    return Client.post(`${resource}`, params, {
      headers: {
        action: "list-bet-history",
      },
    });
  },
  total(payload) {
    let params = prepareSearchParams(payload);
    params = {
      ...payload,
      ...params,
      username: payload.search,
    };
    if (payload.startDate) {
      params.filters.push({
        searchby: "created_at",
        searchoperator: ">",
        search: `${payload.startDate} 00:00:00`,
      });
    }
    if (payload.toDate) {
      params.filters.push({
        searchby: "created_at",
        searchoperator: "<",
        search: `${payload.toDate} 23:59:59`,
      });
    }
    if (payload.channelType) {
      params.filters.push({
        searchby: "channel_type",
        searchoperator: "=",
        search: payload.channelType,
      });
    }
    if (payload.channelId) {
      params.filters.push({
        searchby: "channel_id",
        searchoperator: "=",
        search: payload.channelId,
      });
    }
    return Client.post(`${resource}`, params, {
      headers: {
        action: "list-bet-history-total",
      },
    });
  },
};
