import Client from "@/repositories/Clients/AxiosClient";
import Config from "@/repositories/Clients/AxiosDefaultConfig";
import { prepareSearchParams } from "@/libs/helper";

const resource = `${Config.basePath}/v1/api/admin/report`;

export default {
  index(payload) {
    let params = prepareSearchParams(payload);
    params = {
      ...payload,
      ...params,
      username: payload.search,
    };
    if (payload.fightMatchId) {
      params.filters.push({
        searchby: "fight_match_id",
        searchoperator: "=",
        search: payload.fightMatchId,
      });
    }
    return Client.post(`${resource}`, params, {
      headers: {
        action: "list-fight-bet-history",
      },
    });
  },
  total(payload) {
    let params = prepareSearchParams(payload);
    params = {
      ...payload,
      ...params,
      username: payload.search,
    };
    if (payload.fightMatchId) {
      params.filters.push({
        searchby: "fight_match_id",
        searchoperator: "=",
        search: payload.fightMatchId,
      });
    }
    return Client.post(`${resource}`, params, {
      headers: {
        action: "list-fight-bet-history-total",
      },
    });
  },
  summary(payload) {
    let params = prepareSearchParams(payload);
    params = {
      ...payload,
      ...params,
    };
    return Client.post(`${resource}`, params, {
      headers: {
        action: "cal-bet-fight",
      },
    });
  },
};
