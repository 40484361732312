import Vue from 'vue'
import currency from 'currency.js'

Vue.filter('currency', (value, currencyCode = null, precision = 2) => {
  if (typeof value === 'undefined' || value == null) {
    return ''
  }

  const p = countDecimalDigits(value)

  if (currencyCode === 840) {
    const USD = value => currency(value, { precision: p, symbol: '$' })
    return USD(value).format()
  } if (currencyCode === 116) {
    const RIEL = value => currency(value, { precision: p, symbol: '៛' })
    return RIEL(value).format()
  } if (currencyCode === 764) {
    const C = value => currency(value, { precision: p, symbol: '฿' })
    return C(value).format()
  } if (currencyCode === 418) {
    const C = value => currency(value, { precision: p, symbol: '₭' })
    return C(value).format()
  }
  const C = value => currency(value, { precision: p, symbol: '' })
  return C(value).format()
})

function countDecimalDigits(number) {
  let numberString = number.toString()

  const decimalIndex = numberString.indexOf('.')
  if (decimalIndex !== -1) {
    let decimalDigits = numberString.length - decimalIndex - 1
    while (decimalDigits > 0 && numberString.endsWith('0')) {
      numberString = numberString.slice(0, -1)
      decimalDigits--
    }

    return decimalDigits || 0
  }

  return 0
}
